const TEAMS = [
    {
      name: 'Padres',
      pathName: 'padres',
      id: 135,
      check: 'this._hasHomerun(game)', // Padres Free Jumbo Jack if the team hits a homerun
      region: 'San Diego',
      fullName: 'San Diego Padres',
      demonym: 'San Diegans',
      promotions: [
        'jack',
        'rubio',
        'petco'
      ]
    },
    {
      name: 'Dbacks',
      pathName: 'dbacks',
      id: 109,
      check: 'this._hasHomerun(game)', // Dbacks Free Jumbo Jack if the team hits a homerun
      region: 'Phoenix',
      fullName: 'Arizona Diamondbacks',
      demonym: 'Arizonans',
      promotions: [
        'jack'
      ]
    }
  ];

  export default TEAMS;
