const TEXT = {
  "homeruns": [   // homeruns text, indexed by the number of runs scored on the homerun
    null,           // 0 run homerun
    'solo',         // 1 run homerun
    '2 run',        // 2 run homerun
    '3 run',        // 3 run homerun
    'grand slam',   // 4 run homerun
  ]
}

export default TEXT;
