const PROMOTIONS = {
  'jack' :
    {
      linkText: '<a href="https://www.jackinthebox.com/location" target="_new">Click here to find your closest location</a>',
      check: 'this._hasHomerun(game)',
      prize: 'a free Jumbo Jack',
      primary: true
    },
  'rubio' :
    {
      linkText: '<a href="https://order.rubios.com/location?_gl=freejumbojack" target="_new">Click here to find your closest Rubio\'s location</a>',
      check: 'this._hasStolenBase(game)',
      prize: 'a free taco',
      primary: false
    },
    'petco' :
    {
      linkText: '<a href="https://www.petco.com/shop/en/petcostore/c/padres-k9" target="_new">Click here to see details of the deal and participating locations</a>',
      check: 'this._hasNineKs(game)',
      prize: 'a Petco Discount',
      primary: false
    },
}

export default PROMOTIONS;
