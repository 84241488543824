import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import TEAMS from '../../constants/teams';
import PROMOS from '../../constants/promotions';
import TEXT from '../../constants/text';

class Giveaway extends React.Component {
  render() {
    let me = this,
        props = me.props,
        game = props && props.game,
        type = props && props.type,
        team = props && props.team,
        scheduledNext = props && props.scheduledNext,
        promo = PROMOS[type],
        linkText = promo.linkText,
        status = (game && promo && promo.check) ? eval(promo.check) : 'loading'; // eslint-disable-line no-eval

    return(
      <Fragment>
        <div className={`question ${ promo.primary ? 'primary':'secondary'}`}>
          <h1>Did the
          { promo.primary &&
            <select value={(team && team.pathName) || ''} onChange={(e) => { window.location = '/' + e.target.value }}>
              { TEAMS.map((t) => (<option value={t.pathName} key={t.name}>{t.name}</option>)) }
            </select>
          }
          { !promo.primary &&
              ' ' + team.name + ' '
          }
          earn us { promo.prize }?</h1>
        </div>
        <div className={`giveaway-wrapper ${type}`}>
          <div className="giveaway-header">
            <h2>{ (status !== 'loading' && (status && status.result ? 'Yes!' : 'No :(')) || '...' }</h2>
          </div>
          <div className="giveaway-main">
            <p>{ parse(this._printStatus(status)) }  { status !== 'loading' && parse(linkText) }</p>
            { promo.primary && scheduledNext && <p>{ this._scheduledNextString(scheduledNext) }</p> }
          </div>
        </div>
      </Fragment>
    )
  }

  _printStatus(status) {
    let me = this,
        props = me.props,
        team = props && props.team,
        type = props && props.type,
        homerunsText = TEXT && TEXT['homeruns'],
        ret = 'Loading...'; // default behavior, status is loading while waiting for game response
        if(type === 'jack') {  // Free Jumbo Jack Wording
          if(status && status.result) {     // if a result exists, then we have an event that triggers a "Yes"
            ret = `<span title="${status.result.description}">You get a free Jumbo Jack from participating ${ team && team.region } area Jack In The Box locations with the purchase of a large beverage thanks to ${ status.matchup.batter.fullName } who hit a ${ homerunsText[status.result.rbi] } homerun in yesterday's game.</span>`;
          } else if(status === undefined) { // if status has been set to undefined, we've fetched the game, but no winning event was ffound
            ret = `Sorry, Dawg.  You'll have to buy your own Jumbo Jack today.  No ${ team && team.name } hit a homerun yesterday.  Still want to grab a Jumbo jack?`
          }
        }
        if(type === 'rubio') { // Free Taco Wording
          if(status && status.result) {
            ret = `<span title="${status.details.description}">${ status.details.description.match(/^.*(?=(steals))/)[0] } stole a base, so you get a free taco with any purchase at Southern California Rubio's locations!  Use the code "GOPADRES" when ordering online</span>`;
          } else if(status === undefined) {
            ret = `Bummer, no free taco today.  No ${ team && team.name } stole a base yesterday.  Want some tacos anyway?`
          }
        }
        if(type === 'petco') { // 25% off at Petco
          if(status && status.result) {
            ret = `Padres pitchers combined for ${status.result.totalKs} strikeouts, so Petco Vital care members get 25% off of their total purchase on qualified items.`
          } else if(status && status.totalKs) {
            ret = `Nope, Padres pitchers only combined for ${status.totalKs} strikeouts yesterday, so you'll have to pay full price today.`
          }
        }
        return ret;
  }

  _scheduledNextString(scheduledNext) {
    let me    = this,
        props = me && me.props,
        team  = props && props.team,
        teamName = team && team.name;

    if(!scheduledNext) {
      return
    }
    let formatString = "h:mma dddd, MMMM D",
        startTime = scheduledNext && dayjs(scheduledNext.gameDate),
        isHome = scheduledNext && this._isHome(scheduledNext),
        ret = 'Watch the ' + teamName + ' at ' + startTime.format(formatString) + ' to see if they can win you a free Jumbo Jack for tomorrow ';
        if(scheduledNext && scheduledNext.venue && scheduledNext.teams) {
          if(isHome) {
            ret += ' when they host the ' + scheduledNext.teams.away.team.name + ' at ' + scheduledNext.venue.name + '.'
          } else {
            ret += ' when they visit ' + scheduledNext.venue.name + ' to take on the ' + scheduledNext.teams.home.team.name + '.'
          }
        }
        return ret
  }

  _isHome(game) {
    // determine if team in the props is home team from game object or schedule object (game || scheduledNext)
    let me    = this,
        props = me && me.props,
        team  = props && props.team,
        teams = (game && game.gameData && game.gameData.teams) || (game && game.teams);
    return team && teams && teams.home && (teams.home.id === team.id || (teams.home.team && teams.home.team.id === team.id));
  }

  _isTeamPlayer(game, playerId) {
    // Returns true if playerId is on the team provided in the props
    let me    = this,
        isHome = me._isHome(game),
        homeTeam = game && game.liveData && game.liveData.boxscore && game.liveData.boxscore.teams && game.liveData.boxscore.teams.home && game.liveData.boxscore.teams.home.players,
        awayTeam = game && game.liveData && game.liveData.boxscore && game.liveData.boxscore.teams && game.liveData.boxscore.teams.away && game.liveData.boxscore.teams.away.players;
        return(
          (isHome && homeTeam["ID" + playerId] !== undefined)
            ||
          (!isHome && awayTeam["ID" + playerId] !== undefined)
        )
  }

  _hasHomerun(game) {
    // Find the first homerun by the team in the props
    let allPlays = game && game.liveData && game.liveData.plays && game.liveData.plays.allPlays;

    return allPlays && allPlays.find((play) => {
      if(play && play.result && play.result.eventType === "home_run") {
        // homerun by somebody, check if it's the right team
        if(this._isTeamPlayer(game, play.matchup.batter.id)) {
          return play
        }
      }
      return false;
    });
  }

  _hasStolenBase(game) {
    // Find the first stolen base by the team
    let allPlays = game && game.liveData && game.liveData.plays && game.liveData.plays.allPlays,
        stolenBasePlay;

    allPlays && allPlays.find((play) => {
      return play.playEvents.find((playEvent) => {
        if(playEvent && playEvent.details && (playEvent.details.eventType === "stolen_base_2b" || playEvent.details.eventType === "stolen_base_3b")) {
          // Somebody stole second or third
          if(this._isTeamPlayer(game, play.matchup.batter.id)) {
              stolenBasePlay = playEvent;
              stolenBasePlay.result = "SB";  // status.result determines if it is a win or not
              return playEvent
          }
          return false
        }
        return false
      });
    });
    return stolenBasePlay;

  }

  _hasNineKs(game) {
    // Determine if the team had 9 or more strikeouts pitching
    let me       = this, 
        props    = me && me.props,
        team     = props && props.team,
        boxscore = game && game.liveData && game.liveData.boxscore,
        teamObj  = boxscore && boxscore.teams ? (boxscore.teams.away.team.id === team.id ? boxscore.teams.away : boxscore.teams.home) : null,
        totalKs  = teamObj && teamObj.teamStats && teamObj.teamStats.pitching && teamObj.teamStats.pitching.strikeOuts;

    if (totalKs >= 9) {
      return {
        result: {
          totalKs,
        }
      }
    } else {
      return {
        totalKs
      }
    }
  }


}

export default Giveaway;
